export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/basalt": [3],
		"/blink": [4],
		"/bridge2": [5],
		"/cave": [6],
		"/construction": [7],
		"/csgSandbox": [8],
		"/depthPrepassDemo": [9],
		"/fogTest": [10],
		"/geodesicsTestbench": [11],
		"/gn_inst_test": [12],
		"/godrays_test": [13],
		"/nexus": [14],
		"/particle_conduit": [15],
		"/pinklights": [16],
		"/pk_pylons": [17],
		"/rainy": [18],
		"/runeGenTest": [19],
		"/smoke": [20],
		"/stadium": [21],
		"/stone": [22],
		"/subdivided": [23],
		"/terrainSandbox": [24],
		"/terrainTest": [25],
		"/tessellationSandbox": [26],
		"/walkways": [27]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';